<template>
  <router-view />
</template>

<style lang="scss">
@import '@/scss/main.scss';

#app {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>

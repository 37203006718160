import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import Directives from './directives';
import InputComponent from '@/components/InputComponent.vue';
declare global {
  interface Window {
    userData: {
      created_at: null | string;
      email: string;
      language_id: number;
      name: string;
      scope: string[];
      updated_at: null | string;
    } | null;
    noImageBMW: string;
    noImageMINI: string;
    fallbackImage: string;
  }
}
window.noImageBMW = require('@/assets/icons/bmw.svg');
window.noImageMINI = require('@/assets/icons/mini.svg');
window.fallbackImage = require('@/assets/photos/carFallBack.png');

axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

axios
  .get('/api/user-info')
  .then((response) => {
    window.userData = response.data;
  })
  .finally(() => {
    const app = createApp(App);

    Directives(app);

    app.component('InputComponent', InputComponent);
    app.use(router).mount('#app');
  });
